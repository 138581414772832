import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import InputField from "../../components/InputField/InputField";
import LightNavBar from "../../components/LightNavBar/LightNavBar";
import TextArea from "../../components/TextArea/TextArea";
import Select from "react-select";
import { Languages } from "../../assets/data";
import BlueButton from "../../components/BlueButton/BlueButton";
import { Controlled as CodeMirror } from "react-codemirror2";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/elegant.css";
import "codemirror/theme/material.css";
import { axiosInstance } from "../../utils/config";
import Toast from "../../components/Toast/Toast";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import RelatedSnippets from "../../sections/RelatedSnippets/RelatedSnippets";

function Submission() {
  const [text, setText] = useState("");
  const [newSnippetId, setNewSnippetId] = useState();
  const [title, setTitle] = useState("");
  const [position, setPosition] = useState("");
  const [language, setLanguage] = useState("javascript");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [githubModalOpen, setGithubModalOpen] = useState(false); // For GitHub modal
  const [githubLink, setGithubLink] = useState(""); // Store GitHub link
  const [confirmReplace, setConfirmReplace] = useState(false); // Confirmation for replacing text

  const navigate = useNavigate();

  // Show Login page if not logged in.
  const cookies = new Cookies();
  const userCookie = cookies.get("token");
  useEffect(() => {
    if (!userCookie) {
      navigate("/login");
    }
  }, [userCookie]);

  const handleGithubSubmit = () => {
    if (text.trim()) {
      // Ask user for confirmation if text already exists
      setConfirmReplace(true);
    } else {
      fetchGithubContent();
    }
  };

  const fetchGithubContent = () => {
    setGithubModalOpen(false);
    setSubmitting(true);
    axiosInstance
      .post(
        "snippets/getfromgithub/",
        { github_url: githubLink },
        {
          headers: {
            Authorization: `Token ${userCookie}`,
          },
        }
      )
      .then((response) => {
        // Append the message about the GitHub source
        const githubMessage = `\n\n/* This code has been taken from GitHub using the "Get Code from GitHub" feature. Source: ${githubLink} */`;
        setText(response.data.file_content + githubMessage);
        setSubmitting(false);
      })
      .catch((err) => {
        // Handle error response from the server
        if (err.response && err.response.data && err.response.data.error) {
          setError(err.response.data.error); // Set the error message
        } else {
          setError("An unexpected error occurred. Please try again."); // Generic error message
        }
        setGithubModalOpen(true);
        setSubmitting(false);
      });
  };

  const handleSubmission = () => {
    setSubmitting(true);
    let parsedText = text;
    if (!title || !parsedText) {
      if (!title && !parsedText) {
        setError("Snippet / Title must not be empty!");
        setSubmitting(false);
        return;
      }
      if (!title) {
        setError("Title must not be empty!");
        setSubmitting(false);
      }
      if (!parsedText) {
        setError("Snippet must not be empty!");
        setSubmitting(false);
      }
      return;
    }
    axiosInstance
      .post(
        "/snippets/",
        {
          title,
          text: JSON.stringify(text),
          language,
          job_role: position,
          description,
          headers: {
            Authorization: `Token ${userCookie}`,
          },
        },
        { withCredentials: true }
      )
      .then((data) => {
        setNewSnippetId(data.data?.id);
        setSubmitting(false);
        setShowModal(true);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  };

  const onCursor = () => {};
  const onMount = () => {};
  const onGutterClick = () => {};
  const setRef = () => {};

  // Styles for MUI Modal box
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
  };

  console.log({ description, language, position, text, title });

  return (
    <div className="relative">
      <LightNavBar active="none" />
      {error && <Toast message={error} type="error" />}
      {showModal && <ConfirmationModal id={newSnippetId} />}

      <div className="page-container px-10 content flex items-center justify-around">
        <div className="right w-7/12 mb-8">
          <form action="">
            {/* Updated section: Make a new submission with GitHub button */}
            <div className="flex items-center justify-between">
              <h6 className="text-black my-6 font-bold">
                Make a new submission
              </h6>
              <BlueButton handleClick={() => setGithubModalOpen(true)}>
                Import Code from GitHub
              </BlueButton>
            </div>

            <InputField
              classNames="my-7"
              label="Title"
              value={title}
              handleChange={(e) => setTitle(e.target.value)}
              placeholder="Choose a short descriptive title"
            />
            <div className="select">
              <label htmlFor="select">Language</label>
              <Select
                className=""
                id="select"
                onChange={(selectedOption) => setLanguage(selectedOption.value)}
                classNamePrefix="select"
                defaultValue={Languages[0]}
                isClearable={true}
                isSearchable={false}
                name="language"
                options={Languages}
              />
            </div>

            <InputField
              value={position}
              handleChange={(e) => setPosition(e.target.value)}
              classNames="my-7"
              label="What position are you interviewing for?"
              placeholder="e.g. Junior, senior, Principal Engineer..."
            />

            <TextArea
              value={description}
              handleChange={(e) => setDescription(e.target.value)}
              classNames="my-16"
              innerClassNames="h-[89px]"
              label="Description"
              placeholder="Describe your question here"
            />
          </form>

          <div className="editor-field">
            <span>Solution</span>
            <CodeMirror
              ref={setRef}
              value={text || "\n\n\n\n\n\n\n\n\n"}
              onBeforeChange={(editor, data, value) => {
                setText(value);
              }}
              onCursor={onCursor}
              onGutterClick={onGutterClick}
              className="editor text-sm"
              editorDidMount={onMount}
              options={{
                readOnly: false,
                mode: language,
                lineNumbers: true,
                lineWrapping: true,
              }}
            />
          </div>

          <div className="flex items-center justify-between">
            <BlueButton handleClick={handleSubmission}>
              {submitting ? "Submitting..." : "Submit your code"}
            </BlueButton>
          </div>
        </div>

        <div className="left w-3/12">
          <RelatedSnippets />
        </div>
      </div>

      {/* MUI GitHub Modal */}
      <Modal
        open={githubModalOpen}
        onClose={() => setGithubModalOpen(false)}
        aria-labelledby="github-modal-title"
        aria-describedby="github-modal-description"
      >
        <Box sx={modalStyle}>
          <h2 style={{ marginTop: 0 }} id="github-modal-title">
            Enter GitHub Link
          </h2>
          <p>
            Enter the URL of the file you want to fetch from GitHub. The content
            of the file will be appended to the current code editor.
          </p>
          <p style={{ fontSize: "x-small" }}>
            Note: Enter the link to a file inside a public GitHub Repository,
            not the link to Repository
          </p>
          <InputField
            value={githubLink}
            handleChange={(e) => setGithubLink(e.target.value)}
            placeholder="Enter GitHub file URL"
          />
          <div className="flex justify-between mt-4">
            <BlueButton handleClick={handleGithubSubmit}>Submit</BlueButton>
            <BlueButton handleClick={() => setGithubModalOpen(false)}>
              Cancel
            </BlueButton>
          </div>
        </Box>
      </Modal>

      {/* MUI Confirmation Modal */}
      {confirmReplace && (
        <Modal
          open={confirmReplace}
          onClose={() => setConfirmReplace(false)}
          aria-labelledby="confirmation-modal-title"
          aria-describedby="confirmation-modal-description"
        >
          <Box sx={modalStyle}>
            <h2 id="confirmation-modal-title">Confirmation</h2>
            <p id="confirmation-modal-description">
              The current content will be replaced by the file content from
              GitHub. Do you want to continue?
            </p>
            <div className="flex justify-between mt-4">
              <BlueButton
                handleClick={() => {
                  fetchGithubContent();
                  setConfirmReplace(false);
                }}
              >
                Confirm
              </BlueButton>
              <BlueButton handleClick={() => setConfirmReplace(false)}>
                Cancel
              </BlueButton>
            </div>
          </Box>
        </Modal>
      )}

      <Footer />
    </div>
  );
}

export default Submission;
