import React from "react";

function TextArea({
  label,
  placeholder,
  classNames,
  innerClassNames,
  value,
  handleChange,
}) {
  return (
    <div className={`${classNames} mt-4 text-sm`}>
      <label htmlFor="" className="font-medium text-xs">
        {label}
      </label>
      <div className="input-wrapper w-full mt-1 h-[38px] ">
        <textarea
          className={`${innerClassNames} w-full h-[156px] resize-none rounded-sm border-[1px] grey-border flex items-center font-medium text-sm justify-start p-2 pl-3 outline-none`}
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        ></textarea>
      </div>
    </div>
  );
}

export default TextArea;
