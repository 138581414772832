import {
  FETCH_ALL,
  START_LOADING,
  END_LOADING,
  SEARCH_SNIPPETS,
} from "../../constants/actionTypes";
import * as api from "../api/index.js";

//Action creators
export const getSnippets = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchSnippets();
    const action = { type: FETCH_ALL, payload: data };
    dispatch(action);
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: END_LOADING });
  }
};

// Action creator for searching snippets
export const searchSnippets = (searchTerm) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    // API call to search for snippets
    const { data } = await api.searchSnippets(searchTerm);

    // Dispatch the action to the reducer
    const action = { type: SEARCH_SNIPPETS, payload: data };
    dispatch(action);

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: END_LOADING });
  }
};
