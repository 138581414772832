import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import "./Footer.css";
import { useParams } from "react-router-dom";

function Footer() {
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);
  return (
    <footer className="bg-[#0041CA] p-10 flex justify-between font-poppins items-start flex-col">
      <div className="flex justify-between font-poppins items-start w-full">
        <div className="logo-section flex-[3]">
          <img src={Logo} alt="" />
        </div>
        <div className="links-section flex flex-[2] items-start justify-between">
          <div className="quick-links">
            <b className="text-sm text-white">Code Reviews</b>
            <ul className="my-2 text-xs">
              <li>
                <Link to="/explore/java">Java</Link>
              </li>
              <li>
                <Link to="/explore/python">Python</Link>
              </li>
              <li>
                <Link to="/explore/php">PHP</Link>
              </li>
              <li>
                <Link to="/explore/ruby">Ruby</Link>
              </li>
              <li>
                <Link to="/explore/javascript">Javascript</Link>
              </li>
              <li>
                <Link to="/explore/clike"> C/C++</Link>
              </li>
              <li>
                <Link to="/explore/rust"> Rust</Link>
              </li>
              <li>
                <Link to="/explore/typescript"> Typescript</Link>
              </li>
              <li>
                <Link to="/explore/go"> Go</Link>
              </li>
              <li>
                <Link to="/explore/julia"> Julia</Link>
              </li>
              <li>
                <Link to="/explore/swift"> Swift</Link>
              </li>
              <li>
                <Link to="/explore/kotlin"> Kotlin</Link>
              </li>
            </ul>
          </div>
          <div className="mobile-footer-right flex justify-around w-7/12">
            <div className="quick-links">
              <b className="text-sm text-white">Resources</b>
              <ul className="my-2 text-sm">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/comingsoon">About</Link>
                </li>
                <li>
                  <Link to="/comingsoon">Blog</Link>
                </li>
                <li>
                  <Link to="/comingsoon">Interview</Link>
                </li>
              </ul>
            </div>
            <div className="quick-links">
              <b className="text-sm text-white">Follow us</b>
              <ul className="my-2 text-sm">
                <li>
                  <Link to="/comingsoon">LinkedIn</Link>
                </li>
                <li>
                  <Link to="/comingsoon">Twitter</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between flex-col items-center	mt-10">
        {/* privacy policy and terms and conditions */}
        <div>
          <ul className="my-2 text-xs text-white">
            <Link to="/terms" className="text-white">
              Terms and Conditions
            </Link>{" "}
            |{" "}
            <Link to="/privacypolicy" className="text-white">
              Privacy Policy
            </Link>
          </ul>
        </div>
        <div>
          <p className="text-white text-xs">
            © 2021 Code Review, All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
