import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";

import Footer from "../../components/Footer/Footer";
import useWindowDimensions from "../../utils/useWindowDimensions";
import NavBarMobile from "../../components/Mobile/NavBarMobile/NavBarMobile";
import MobileSideBar from "../../components/Mobile/MobileSideBar/MobileSideBar";
import { useSelector } from "react-redux";

function Terms({ token }) {
  let { width } = useWindowDimensions();
  const [showSideBar, setShowSideBar] = useState(false);
  const { isLoading } = useSelector((state) => state.users);
  useEffect(() => {
    console.log(isLoading);
  }, [isLoading]);

  const toggleShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  return (
    <>
      <div className="relative">
        {showSideBar && <MobileSideBar toggleShowSideBar={toggleShowSideBar} />}
        <header className="pt-3 pb-[3.75rem] mb-3 about-header h-auto relative">
          {width < 834 ? (
            <NavBarMobile toggleShowSideBar={toggleShowSideBar} />
          ) : (
            <NavBar />
          )}
        </header>
        <main className="black-color">
          <div className="max-w-4xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">
              Customer Terms and Conditions
            </h1>
            <p className="mb-4">
              These Customer Terms and Conditions apply to all Order Forms
              entered into on or after July 20, 2024, and for all free or trial
              versions, Community Edition and/or Customers utilizing the
              CodingBlindspots.com Services not pursuant to an Order Form as of such date.
            </p>
            <p className="mb-6">
              Welcome to CodingBlindspots.com! Please read these Customer Terms and
              Conditions carefully because they govern each Customer’s access to
              and use of the CodingBlindspots.com Platform and Services.
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              1. Agreement to Terms
            </h2>
            <p className="mb-2">
              1.1) By using the Services, a Customer agrees to be bound by these
              Customer Terms and Conditions and the General Terms and
              Conditions, which are incorporated by reference. If you do not
              understand any terms in these Customer Terms and Conditions or the
              General Terms and Conditions, please contact us before using the
              Services.
            </p>
            <p className="mb-6">
              1.2) You may not access or use any Services unless you agree to
              abide by all of these Customer Terms and Conditions and the
              General Terms and Conditions (collectively, the “Terms”).
            </p>

            <h2 className="text-2xl font-semibold mb-4">2. Definitions</h2>
            <p className="mb-6">
              2.1) Certain capitalized terms used in these Customer Terms and
              Conditions are defined in the General Terms and Conditions.
            </p>

            <h2 className="text-2xl font-semibold mb-4">3. Services</h2>
            <p className="mb-2">
              3.1) CodingBlindspots.com Platform. The Customer may access and use the
              CodingBlindspots.com Platform solely for its and its Affiliates own business
              purposes to connect with Community Members and utilize the
              Services set forth in an Order Form or otherwise mutually agreed
              by CodingBlindspots.com and the Customer.
            </p>
            <p className="mb-2">
              3.2) CodingBlindspots.com Services. CodingBlindspots.com will provide the Services set
              forth in a fully executed Order Form or otherwise mutually agreed
              in writing by CodingBlindspots.com and the Customer.
            </p>
            <p className="mb-2">
              3.3) Restrictions. The Customer shall not (and shall not permit
              any third party to), directly or indirectly: (i) reverse engineer,
              decompile, disassemble, or otherwise attempt to discover the
              source code, object code, or underlying structure, ideas, or
              algorithms of the Service (except to the extent Applicable Laws
              specifically prohibit such restriction); ...
            </p>
            {/* Continue with the rest of the sections in a similar manner */}
            {/* Ensure to break each section into h2, h3, p elements as needed */}

            <h2 className="text-2xl font-semibold mb-4">
              4. Community Member Submissions, Community Members, and Output
            </h2>
            <p className="mb-2">
              4.1) CodingBlindspots.com does not endorse any Community Member. CodingBlindspots.com is
              not responsible for any damage or harm resulting from a Customer’s
              communications or interactions with Community Members or other
              customers, either through the Services or otherwise.
            </p>
            <p className="mb-6">
              4.2) Any use or reliance of Community Member Submissions that
              Customer receives is at Customer’s own risk. ...
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              5. Rewards and CodingBlindspots.com Fees
            </h2>
            <p className="mb-2">
              5.1) Rewards. If applicable to the Customer’s Program and in
              accordance with the Program Policy, a Customer may award Rewards
              to those Community Members who participate in the Customer’s
              Programs and/or submit Community Member Submissions that meet the
              Customer’s requirements. ...
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              6. Programs and Program Materials
            </h2>
            <p className="mb-2">
              6.1) CodingBlindspots.com makes available through the CodingBlindspots.com Platform both
              managed Programs, under which CodingBlindspots.com is responsible for the
              management and the administration of a Customer’s Programs with
              input and approval from the Customer as mutually agreed throughout
              the Program.
            </p>

            {/* Continue for other sections... */}

            <p className="mt-6 text-sm text-gray-600">
              Last updated: July 20, 2024
            </p>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
}

export default Terms;
