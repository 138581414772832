import React from "react";
import LightNavBar from "../../components/LightNavBar/LightNavBar";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

const NoPage = () => {
  return (
    <>
      <LightNavBar active="none" />
      <div className="flex flex-col items-center justify-center min-h-[70vh]">
        <h3>This page Could not be found.</h3>
        <h5 className="m-0">
          <Link to="explore" className="text-black underline">
            Click here
          </Link>{" "}
          to explore snippets
        </h5>
        <h5 className="m-0">
          <Link to="" className="text-black underline">
            Click here
          </Link>{" "}
          to go home
        </h5>
      </div>
      <Footer />
    </>
  );
};

export default NoPage;
