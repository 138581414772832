import React from 'react'

function BlueArrow() {
    return (
        <>
            <i className="ri-arrow-drop-right-line flex items-center justify-center rounded-sm text-[2.6rem] text-white p-3 h-[26px] w-[25px] primary-bg"></i>
        </>
    )
}

export default BlueArrow