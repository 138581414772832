import React from "react";
import LightNavBar from "../../components/LightNavBar/LightNavBar";
import Footer from "../../components/Footer/Footer";

const Comingsoon = () => {
  return (
    <>
      <LightNavBar />
      <div style={{ padding: "10rem", height: "58vh" }}>
        <h1 style={{ textAlign: "center" }}>Coming Soon</h1>
        <p style={{ textAlign: "center" }}>
          We're currently working on something awesome. Check back later for
          updates!
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Comingsoon;
