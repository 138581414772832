import React, { useEffect, useState } from "react";
import { trackClickEvent } from "../../analytics";

import FormsLayout from "../../Layouts/FormsLayout";
import InputField from "../../components/InputField/InputField";
import BlueButton from "../../components/BlueButton/BlueButton";
import { axiosInstance } from "../../utils/config";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Toast/Toast";
import { useSelector } from "react-redux";

function Login() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [success, setSuccess] = useState("");
  const { me } = useSelector((state) => state.users);

  const handleLogin = (e) => {
    e.preventDefault();
    trackClickEvent(e);
    setLoginError("");
    setIsLoggingIn(true);
    axiosInstance
      .post(`/api/v1/token/login`, userInfo)
      .then((tokenresponse) => {
        console.log(tokenresponse.data);
        cookies.set("token", tokenresponse.data.auth_token, { path: "/" });
        setIsLoggingIn(false);
        setUserInfo({ email: "", password: "" });
        setSuccess("Logged in successfully");
        setTimeout(() => {
          navigate("/explore");
        }, 1000);
      })
      .catch((error) => {
        console.log(error.response);
        setLoginError(Object.values(error.response.data)[0][0]);
        setUserInfo({ email: "", password: "" });
        setIsLoggingIn(false);
      });
  };

  useEffect(() => {
    if (me && me.username) {
      navigate("/explore", { replace: true});
    }
  }
  , [me, navigate]);

  return (
    <div className="relative">
      {success && <Toast message={success} type="success" />}
      <FormsLayout welcomeText="Hi, Welcome back!">
        <form className="w-full flex justify-center flex-col items-center">
          <h4 className="font-bold text-xl">Login</h4>
          {loginError && (
            <div className="w-full text-center text-sm bg-red-100 px-4 py-2 rounded-sm text-red-900">
              {loginError}
            </div>
          )}
          <InputField
            handleChange={(e) => {
              trackClickEvent(e);
              setUserInfo({ ...userInfo, email: e.target.value });
            }}
            value={userInfo?.email}
            type="text"
            classNames="w-full"
            label="Email"
            placeholder="Enter your email"
          />

          <InputField
            handleChange={(e) => {
              trackClickEvent(e);
              setUserInfo({ ...userInfo, password: e.target.value });
            }}
            value={userInfo?.password}
            type="password"
            classNames="w-full"
            label="Password"
            placeholder="Enter your password"
          />

          {/* <TextArea label="Message" placeholder="Your full names" classNames="w-full" /> */}

          <a
            className="w-full primary-color text-right text-xs font-medium cursor-pointer"
            href="http://127.0.0.1:8000/displaycode/reset_password/"
          >
            Forgot password?
          </a>
          <div className="w-full" onClick={handleLogin}>
            <BlueButton
              disabled={!userInfo.email || !userInfo.password}
              classNames="w-full py-3 flex justify-center items-center"
            >
              {isLoggingIn ? "Logging in..." : "Login"}
            </BlueButton>
          </div>

          <p className="w-full flex items-center justify-center gap-3 my-4 text-right text-xs font-medium">
            Don't have an account?{" "}
            <span
              className="primary-color cursor-pointer"
              onClick={(e) => {
                trackClickEvent(e);
                navigate("/register");
              }}
            >
              Sign up here
            </span>
          </p>
        </form>
      </FormsLayout>
    </div>
  );
}

export default Login;
