import React from 'react'
import SnippetSkeletonCard from "./SnippetSkeletonCard";

const SnippetSkeleton = props => (
    <div className="flex flex-wrap">
        {/* show 12 snippet skeleton, because we will show maximum 12 snippets per page */}
        {[...Array(12)].map((card, index) => (
            <SnippetSkeletonCard key={index} />
        ))}
    </div>
)

export default SnippetSkeleton