import { END_LOADING, FETCH_CURRENT_USER, START_LOADING, LOGOUT } from "../../constants/actionTypes";

export const users = (
    state = { isLoading: false, users: [], me: {} },
    action
) => {
    switch (action.type) {
        case FETCH_CURRENT_USER:
            return { ...state, me: action.payload }
        case START_LOADING:
            return { ...state, isLoading: true }
        case END_LOADING:
            return { ...state, isLoading: false }
        case LOGOUT:
            return { ...state, me: {} }
        default:
            return state;
    }
};
