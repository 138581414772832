
import React from 'react'
import { useSelector } from "react-redux";
import Footer from '../../components/Footer/Footer';
import LightNavBar from '../../components/LightNavBar/LightNavBar'
import Languages from '../../sections/Languages/Languages'
import Snippets from '../../sections/Snippets/Snippets';
import useWindowDimensions from '../../utils/useWindowDimensions';
import "./Search.css";
import { searchSnippets } from '../../redux/actions/snippets';

function Search() {
    const { width } = useWindowDimensions();
    const { searchedSnippets, isLoading, totalSnippets } = useSelector(state => state.snippets);


    return (
        <div className="search-container text-black w-[100vw] h-[100vh] white-bg">
            <LightNavBar />
            <Languages snippets={searchedSnippets} isLoading={isLoading} />
            <Snippets snippets={searchedSnippets} isLoading={isLoading} screenWidth={width} />
            <Footer />
        </div>
    )
}

export default Search
