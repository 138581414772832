import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";

import Footer from "../../components/Footer/Footer";
import useWindowDimensions from "../../utils/useWindowDimensions";
import NavBarMobile from "../../components/Mobile/NavBarMobile/NavBarMobile";
import MobileSideBar from "../../components/Mobile/MobileSideBar/MobileSideBar";
import { useSelector } from "react-redux";

function Privacypolicy({ token }) {
  let { width } = useWindowDimensions();
  const [showSideBar, setShowSideBar] = useState(false);
  const { isLoading } = useSelector((state) => state.users);
  useEffect(() => {
    console.log(isLoading);
  }, [isLoading]);

  const toggleShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  return (
    <>
      <div className="relative">
        {showSideBar && <MobileSideBar toggleShowSideBar={toggleShowSideBar} />}
        <header className="pt-3 pb-[3.75rem] mb-3 about-header h-auto relative">
          {width < 834 ? (
            <NavBarMobile toggleShowSideBar={toggleShowSideBar} />
          ) : (
            <NavBar />
          )}
        </header>
        <main className="black-color">
          <div className="container mx-auto p-8" style={{border:0}}>
            <h1 className="text-5xl font-bold mb-8 text-center">
              Privacy Policy
            </h1>

            {/* Introduction Table */}
            <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
            <table className="w-full mb-8 border border-gray-300">
              <thead className="bg-gray-200 border-b border-gray-300">
                <tr>
                  <th className="font-bold p-2 border-r border-gray-300 text-left">
                    Question
                  </th>
                  <th className="font-bold p-2 text-left">Answer</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-gray-300">
                  <td className="p-2 w-1/3 max-w-[30%] border-r border-gray-300">
                    What is this policy for?
                  </td>
                  <td className="p-2">
                    This policy explains how we collect and use your personal
                    data.
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="p-2 border-r border-gray-300">
                    Why do we collect data?
                  </td>
                  <td className="p-2">
                    We collect data to improve our services and provide a better
                    user experience.
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border-r border-gray-300">
                    Who does it apply to?
                  </td>
                  <td className="p-2">
                    This policy applies to all users of our services and
                    website.
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Your Personal Data and How We Use It Table */}
            <h2 className="text-2xl font-bold mb-4">
              2. YOUR PERSONAL DATA AND HOW WE USE IT
            </h2>
            <table className="w-full mb-8 border border-gray-300">
              <thead className="bg-gray-200 border-b border-gray-300">
                <tr>
                  <th className="font-bold p-2 border-r border-gray-300 text-left">
                    Your data
                  </th>
                  <th className="font-bold p-2 border-r border-gray-300 text-left">
                    How we use it
                  </th>
                  <th className="font-bold p-2 text-left">
                    Sources and recipients
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-gray-300">
                  <td className="p-2 border-r border-gray-300">
                    Name and Contact Information
                  </td>
                  <td className="p-2 w-1/2 border-r border-gray-300">
                    We use this to contact you regarding services or updates.
                  </td>
                  <td className="p-2">
                    Collected directly from you, shared with our partners for
                    providing services.
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="p-2 border-r border-gray-300">
                    Payment Information
                  </td>
                  <td className="p-2 border-r border-gray-300">
                    To process transactions and prevent fraud.
                  </td>
                  <td className="p-2">
                    Collected via payment gateways, shared with payment
                    processors.
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border-r border-gray-300">Usage Data</td>
                  <td className="p-2 border-r border-gray-300">
                    To analyze and improve our website performance and user
                    experience.
                  </td>
                  <td className="p-2">
                    Collected automatically, shared with analytics tools.
                  </td>
                </tr>
              </tbody>
            </table>

            {/* More Information Table */}
            <h2 className="text-2xl font-bold mb-4">3. MORE INFORMATION</h2>
            <table className="w-full mb-8 border border-gray-300">
              <thead className="bg-gray-200 border-b border-gray-300">
                <tr>
                  <th className="font-bold p-2 border-r border-gray-300 text-left">
                    Question
                  </th>
                  <th className="font-bold p-2 text-left">Answer</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-gray-300">
                  <td className="p-2 w-1/3 max-w-[30%] border-r border-gray-300">
                    Can I access my data?
                  </td>
                  <td className="p-2">
                    Yes, you can request a copy of your data by contacting us.
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="p-2 border-r border-gray-300">
                    How can I delete my data?
                  </td>
                  <td className="p-2">
                    You can request data deletion at any time by sending us a
                    request.
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border-r border-gray-300">
                    Is my data secure?
                  </td>
                  <td className="p-2">
                    We implement strong security measures to protect your data.
                  </td>
                </tr>
              </tbody>
            </table>

            {/* California Privacy Rights Act of 2020 (CPRA) */}
            <h2 className="text-2xl font-bold mb-4">
              4. California Privacy Rights Act of 2020 (CPRA)
            </h2>
            <table className="w-full mb-8 border border-gray-300">
              <thead className="bg-gray-200 border-b border-gray-300">
                <tr>
                  <th className="font-bold p-2 text-left">Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2">
                    The CPRA provides California residents with additional
                    rights regarding their personal information, including the
                    right to know what personal data is collected, the right to
                    delete their data, and the right to opt out of data selling.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
}

export default Privacypolicy;
