import React from 'react'
import "./CheckMark.css";

function CheckMark({ showCheckMark }) {
    return (
        <div className="flex items-center justify-center h-[19px] border-1 grey-border rounded-sm w-[19px]">
            {showCheckMark && <div className="checkmark"></div>}
        </div>
    )
}

export default CheckMark