import React from 'react';

function Modal({ children }) {
    return (
        <div className='bg-opacity-60 flex items-center justify-center bg-slate-800 fixed h-[100vh] w-full z-40 m-auto'>
            {children}
        </div>
    )
}

export default Modal