import React from 'react'
import BlueArrow from '../../components/BlueArrow/BlueArrow'
import FeatureCard from '../../components/FeatureCard/FeatureCard'
import SectionHeader from '../../components/SectionHeader/SectionHeader'


import SubmitIcon from "../../assets/icons/submit.png";
import ReviewIcon from "../../assets/icons/review.png";
import WaitIcon from "../../assets/icons/wait.png";

function WhyUs() {
    return (
        <>
            <SectionHeader>Using CodingBlindSpots</SectionHeader>
            <div className="mb-5 why-use-coding-blindspots flex flex-wrap items-center w-9/12 m-auto justify-around">
                <FeatureCard icon={SubmitIcon}>
                    <p className="text-center my-2 font-bold text-sm">
                        Submit a coding question <br /> and your solution.
                    </p>
                </FeatureCard>
                <BlueArrow />
                <FeatureCard icon={WaitIcon}>
                    <p className="text-center my-2 font-bold text-sm">
                        Hold tight while experienced <br /> professionals review your submission
                    </p>
                </FeatureCard>
                <BlueArrow />
                <FeatureCard icon={ReviewIcon}>
                    <p className="text-center my-2 font-bold text-sm">
                        Come back to review <br /> your feedback
                    </p>
                </FeatureCard>
            </div>
        </>
    )
}

export default WhyUs