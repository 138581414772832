import Avatar from "../assets/images/profile.png";
import MemberAvatar from "../assets/images/avatar.png";

export const Languages = [
    { value: 'javascript', label: 'JavaScript' },
    { value: 'java', label: 'Java' },
    { value: 'python', label: 'Python' },
    { value: 'ruby', label: 'Ruby' },
    { value: 'swift', label: 'Swift' },
    { value: 'go', label: 'Go' },
    { value: 'rust', label: 'Rust' },
    { value: 'php', label: 'PHP' },
    { value: 'clike', label: 'C/C++ or other languages' },
]

export const members = [
    {
        name: "John Dukes",
        profile: MemberAvatar,
        github: "githubusername",
        linkedin: "linkedinusername"
    },
    {
        name: "John Dukes",
        profile: MemberAvatar,
        github: "githubusername",
        linkedin: "linkedinusername"
    },
    {
        name: "John Dukes",
        profile: MemberAvatar,
        github: "githubusername",
        linkedin: "linkedinusername"
    },
    {
        name: "John Dukes",
        profile: MemberAvatar,
        github: "githubusername",
        linkedin: "linkedinusername"
    },
    {
        name: "John Dukes",
        profile: MemberAvatar,
        github: "githubusername",
        linkedin: "linkedinusername"
    },
    {
        name: "John Dukes",
        profile: MemberAvatar,
        github: "githubusername",
        linkedin: "linkedinusername"
    }
]

export const testimonialsOrig = [
    {
        person: "Harshavardhan",
        position: "Budding software engineer",
        profile: Avatar,
        content: "Raghuram has vast experince in software engineering and has been very effective at explaining deep concepts. We built this site together :)"
    }, {
        person: "Deepak Sekar",
        position: "Founder, Profjim and Chowbotics",
        profile: Avatar,
        content: "Raghuram is very detailed oriented, has vast experience in writing scalable software and has worked in multiple companies. He has very good knowledge of industry best practices.His insights have been useful many times, for my startups. "
    }, {
        person: "Sarthak Gokhale",
        position: "Intern software engineer",
        profile: Avatar,
        content: "Raghuram sir has been very helpful in teaching about algorithms, data structures, testing, software architecture and  software engineering interviews in FAANG compaies. His guidance is valuable. "
    }
]

export const testimonials = [
    {
        person: "Harshavardhan",
        position: "Budding software engineer",
        profile: "https://avatars.githubusercontent.com/u/86284121?v=4",
        content: "Raghuram sir has vast experince in software engineering and has been very effective at explaining deep concepts. We built this site together :)"
    }, {
        person: "Tanmay Mutalik",
        position: "Intern Digital Outcomes",
        profile: "https://avatars.githubusercontent.com/u/81510751?v=4",
        content: "Raghuram sir takes time to review every line of code and gives thoughtful comments. His insights into working of linux, servers etc have been useful many times and accelerated my learning"
    }, {
        person: "Sarthak Gokhale",
        position: "Intern Suprkid.in",
        profile: "https://avatars.githubusercontent.com/u/81510751?v=4",
        content: "Raghuram sir has been very helpful in teaching about algorithms, data structures, testing, software architecture and  software engineering interviews in FAANG compaies. His guidance is valuable. "
    }
]
