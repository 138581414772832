import React from "react";
import { Link } from "react-router-dom";
import "./NavBar.css";
import Logo from "../../assets/images/logo.png";
import SearchField from "../SearchField/SearchField";
import Button from "../Button/Button";
import { UilPlus, UilSignOutAlt } from "@iconscout/react-unicons";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../constants/actionTypes";

function NavBar() {
  const cookies = new Cookies();
  const userCookie = cookies.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    cookies.remove("token");
    dispatch({ type: LOGOUT });
    navigate("/");
  };
  return (
    <nav className="text-md flex items-center justify-between p-4">
      <div className="left flex items-center w-[35%] justify-around">
        <Link to="/" className="cursor-pointer">
          <img src={Logo} alt="" />
        </Link>
        <Link to="/explore">Explore</Link>
      </div>
      <div className="right flex items-center w-[60%] justify-end">
        <SearchField bgColor="bg-white" />
        <Button
          redirectTo="/submit"
          parentStyles="mx-1"
          preIcon={<UilPlus size="15" />}
        >
          Submit
        </Button>
        {userCookie ? (
          <div onClick={logout}>
            <Button preIcon={<UilSignOutAlt size="15" />} parentStyles="mx-1">
              Logout
            </Button>
          </div>
        ) : (
          <>
            <Button
              redirectTo="/register"
              preIcon={<UilSignOutAlt size="15" />}
              parentStyles="mx-1"
            >
              Register
            </Button>
            <Button
              redirectTo="/login"
              parentStyles="mx-1"
              preIcon={<UilSignOutAlt size="15" />}
            >
              Login
            </Button>
          </>
        )}
      </div>
    </nav>
  );
}

export default NavBar;
