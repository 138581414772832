import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../utils/config";

const Forgotpassword = () => {
  const [showVerifyForm, setShowVerifyForm] = useState(false);
  const [otp, setOTP] = useState("");
  const [email, setEmail] = useState("");
  const [remainingTime, setRemainingTime] = useState(300); // 5 minutes in seconds

  const handleSubmit = (event) => {
    event.preventDefault();
    axiosInstance
      .post("/generate_otp/", { email: email })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data);
          setEmail("");
          setShowVerifyForm(true);
          startTimer();
        } else {
          // show error message to user
        }
      })
      .catch((error) => {
        // show error message to user
      });
  };

  const handleOTPSubmit = (event) => {
    event.preventDefault();
    axiosInstance
      .post("/verify_otp/", { email: email, otp: otp })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data);
          console.log("I will now redirect you to the password reset page");
          // redirect user to password reset page
        } else {
          // show error message to user
        }
      })
      .catch((error) => {
        // show error message to user
      });
  };

  const startTimer = () => {
    setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);
  };

  useEffect(() => {
    if (remainingTime === 0) {
    }
  }, [remainingTime]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      {!showVerifyForm && (
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
          <button type="submit">Send OTP</button>
        </form>
      )}

      {showVerifyForm && (
        <form onSubmit={handleOTPSubmit}>
          <p>Enter OTP (remaining time: {formatTime(remainingTime)})</p>
          <input
            type="text"
            placeholder="Enter OTP"
            onChange={(e) => {
              setOTP(e.target.value);
            }}
            disabled={remainingTime <= 0}
            required
          />
          <button type="submit">Verify OTP</button>
        </form>
      )}
    </>
  );
};

export default Forgotpassword;
