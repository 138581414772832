import React from 'react'
import ContentLoader from 'react-content-loader'

const SnippetSkeletonCard = props => (
    <div className="snippet-card-skeleton w-[320px] h-[200px] snippet-skeleton-card rounded-md px-2 py-2 pb-1 m-2">
        <ContentLoader viewBox="0 0 400 460" {...props}>
            <circle cx="31" cy="31" r="15" />
            <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
            <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
            <rect x="0" y="60" rx="2" ry="2" width="400" height="170" />
        </ContentLoader>
    </div>
)

export default SnippetSkeletonCard