import React from 'react'

function FeatureCard({icon, children}) {
  return (
    <div className="w-auto m-5 flex items-center justify-center flex-col">
        <img src={icon} alt="" className="my-2 hover:scale-[1.1] transition-all" />
        {children}
    </div>
  )
}

export default FeatureCard