import React from 'react'

function SearchButtonMobile() {
    return (
        <div className='w-[30px] h-[26px] rounded-md light-grey-bg flex mr-2 justify-center items-center'>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 11L8.63895 8.63474M9.94737 5.47368C9.94737 6.66018 9.47604 7.79808 8.63706 8.63706C7.79808 9.47604 6.66018 9.94737 5.47368 9.94737C4.28719 9.94737 3.14929 9.47604 2.31031 8.63706C1.47133 7.79808 1 6.66018 1 5.47368C1 4.28719 1.47133 3.14929 2.31031 2.31031C3.14929 1.47133 4.28719 1 5.47368 1C6.66018 1 7.79808 1.47133 8.63706 2.31031C9.47604 3.14929 9.94737 4.28719 9.94737 5.47368Z" stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" />
            </svg>

        </div>
    )
}

export default SearchButtonMobile