import {
  FETCH_CURRENT_USER,
  START_LOADING,
  END_LOADING,
} from "../../constants/actionTypes";
import * as api from "../api/index.js";

//Action creators
export const getCurrentUser = (token) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchCurrentUser(token);
    const action = { type: FETCH_CURRENT_USER, payload: data };
    dispatch(action);
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: END_LOADING });
  }
};
