import React from "react";
import TestimonialCard from "../TestimonialCard/TestimonialCard";

function TestimonialsMobile({ testimonials }) {
    return (
        <div className="testimonials-container-mobile flex-row flex items-center justify-around py-12 px-24 my-3">
            <i className="ri-arrow-left-s-line text-4xl cursor-pointer"></i>
            <TestimonialCard testimonial={testimonials && testimonials[0]} />
            <i className="ri-arrow-right-s-line text-4xl cursor-pointer"></i>
        </div>
    );
}

export default TestimonialsMobile;
