import React from 'react'
import FeatureCard from '../../components/FeatureCard/FeatureCard'
import SectionHeader from '../../components/SectionHeader/SectionHeader'

import UsersIcon from "../../assets/icons/users.png";
import ReviewsIcon from "../../assets/icons/reviews.png";
import LanguagesIcon from "../../assets/icons/supported_languages.png";

function Achievements() {
    return (
        <>
            <SectionHeader  >Achieved Outcomes</SectionHeader>
                <div className="why-use-coding-blindspots flex flex-wrap items-center w-9/12 m-auto justify-around">
                    <FeatureCard icon={UsersIcon}>
                        <p className="text-center my-2 font-bold text-sm">
                            <big className="text-[18px] block">200+</big>
                            People who can provide feedback <br /> for your codes
                        </p>
                    </FeatureCard>
                    <FeatureCard icon={ReviewsIcon}>
                    <p className="text-center my-2 font-bold text-sm">
                            <big className="text-[18px] block">4000+</big>
                            Reviews of questions and solutions <br /> you can learn from.
                        </p>
                    </FeatureCard>
                    <FeatureCard icon={LanguagesIcon}>
                    <p className="text-center my-2 font-bold text-sm">
                            <big className="text-[18px] block">10+</big>
                            Languages you can get reviews <br /> on at CodingBlindSpots
                        </p>
                    </FeatureCard>
                </div>
        </>
    )
}

export default Achievements
