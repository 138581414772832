import React from 'react'
import RelatedSnippetSkeletonCard from "./RelatedSnippetSkeletonCard";

const RelatedSnippetSkeleton = props => (
    <div className="flex flex-wrap">
        {/* show 6 related snippets skeletons */}
        {[...Array(6)].map((card, index) => (
            <RelatedSnippetSkeletonCard key={index} />
        ))}
    </div>
)

export default RelatedSnippetSkeleton