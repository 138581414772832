import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/elegant.css';
import "./Editor.css";

const Editor = ({
    text,
    language,
    onChange = () => { },
    onCursor = () => { },
    onMount = () => { },
    onGutterClick = () => { },
    editable = true,
    setRef = () => { },
}) => (
    <div className="container">
        <CodeMirror
            ref={setRef}
            value={text}
            onBeforeChange={onChange}
            onCursor={onCursor}
            onGutterClick={onGutterClick}
            className="font-roboto-mono editor text-sm"
            editorDidMount={onMount}
            options={{
                readOnly: !editable,
                mode: "text/javascript",
                lineNumbers: true,
                theme: 'elegant',
                lineWrapping: true,
            }}
        />
    </div>
);

export default Editor;
