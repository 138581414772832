import React from 'react'
import "./SectionHeader.css";

function SectionHeader({children}) {
  return (
    <div className='mt-5 mb-11 section-header w-full flex items-center justify-center'>
        <p className="section-header__p flex items-center font-bold">{children}</p>
    </div>
  )
}

export default SectionHeader