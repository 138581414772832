import React from "react";
import { useNavigate } from 'react-router-dom';

function Button({ children, preIcon, parentStyles, childStyles, redirectTo }) {
    const navigate = useNavigate();
  return (
    <button
      onClick={()=> navigate(redirectTo)}
      className={`${parentStyles} flex items-center justify-between hover:bg-slate-100 bg-white rounded-md px-4 py-[0.3rem] black-color`}
    >
      <span className="mr-1">{preIcon}</span>
      <small className={childStyles}>{children}</small>
    </button>
  );
}

export default Button;
