import React from 'react'

function RelatedSnippet({snippet}) {
    return (
        <div className='my-6'>
            <p className='primary-color text-left text-xs my-1 font-medium'>{snippet.title}</p>
            <div className="flex items-center justify-start gap-7">
                <span className='black-color text-xs font-medium'>{snippet.language}</span>
                <div className="footer my-1 flex items-center justify-end">
                    <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.8 0H1.2C0.54 0 0 0.54 0 1.2V12L2.4 9.6H10.8C11.46 9.6 12 9.06 12 8.4V1.2C12 0.54 11.46 0 10.8 0ZM6.942 5.742L6 7.8L5.058 5.742L3 4.8L5.058 3.858L6 1.8L6.942 3.858L9 4.8L6.942 5.742Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-xs ml-1" data-testid="comment-number">
                        {" "}
                        {snippet.comments?.length} Reviews
                    </span>
                </div>
            </div>
        </div>
    )
}

export default RelatedSnippet
