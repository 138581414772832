import React from 'react'
import ContentLoader from 'react-content-loader'

function RelatedSnippetSkeletonCard(props) {
    return (
        <div className="w-[320px] h-[50px] rounded-md py-2 pb-1 m-2">

                <ContentLoader
                    height={54}
                    width={320}
                    viewBox="0 0 320 54"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                >
                    <rect x="0" y="4" rx="3" ry="3" width="230" height="18" />
                    <rect x="0" y="30" rx="3" ry="3" width="50" height="10" />
                    <rect x="60" y="30" rx="3" ry="3" width="74" height="10" />
                </ContentLoader>
        </div>
    )
}

export default RelatedSnippetSkeletonCard