import React from 'react';

function ConfirmationModal({ id }) {
    return (
        <div className='bg-opacity-60 flex items-center justify-center bg-slate-800 fixed h-[100vh] w-full z-40 m-auto'>
            <div className="card w-[30rem] flex items-center justify-center px-4 h-[200px] rounded-md shadow-md bg-white">
                <p className='text-center'>
                    Thank you for submitting your snippet 🎉
                    <br /> Your submission ID is <span className='px-3 font-bold py-1 rounded-sm bg-slate-200'>{id}</span>. To view your submission, <br /> click <a className='text-blue-600 underline ' href={`/review/${id}`}>here.</a>
                </p>
            </div>
        </div>
    )
}

export default ConfirmationModal