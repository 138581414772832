import React from 'react'
import RelatedSnippetSkeleton from '../../components/RelatedSnippetSkeleton/RelatedSnippetSkeleton'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RelatedSnippet from '../../components/RelatedSnippet/RelatedSnippet';

function RelatedSnippets() {
    const { isLoading, totalSnippets } = useSelector(state => state.snippets);
    const navigate = useNavigate();

    return (
        <div className="related mt-7">
            <b className='primary-color'>Similar reviews</b>
            {isLoading || !totalSnippets.length ? (<RelatedSnippetSkeleton />) : (
                //show a few snippets 
                totalSnippets.slice(0, 6)?.map(snippet => (
                    <div className="hover:bg-slate-50 hover:rounded-sm cursor-pointer" onClick={() => navigate("/review/" + snippet.id)}>
                        <RelatedSnippet snippet={snippet} />
                    </div>
                ))
            )}
            <div className="light-grey-bg h-[160px] mb-4 rounded-lg flex items-center justify-center">
                ads
            </div>
            <div className="light-grey-bg h-[160px] mb-4 rounded-lg flex items-center justify-center">
                ads
            </div>
            <div className="light-grey-bg h-[160px] mb-4 rounded-lg flex items-center justify-center">
                ads
            </div>
        </div>
    )
}

export default RelatedSnippets
