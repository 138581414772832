import React, { useState, useEffect } from "react";

import Banner from "../../components/Banner/Banner";
import NavBar from "../../components/NavBar/NavBar";
import "./About.css";

import WhyUs from "../../sections/WhyUs/WhyUs";
import Achievements from "../../sections/Achievements/Achievements";
import Testimonials from "../../sections/Testimonials/Testimonials";
import { testimonials } from "../../assets/data";
import Footer from "../../components/Footer/Footer";
import useWindowDimensions from "../../utils/useWindowDimensions";
import NavBarMobile from "../../components/Mobile/NavBarMobile/NavBarMobile";
import TestimonialsMobile from "../../components/TestimonialsMobile/TestimonialsMobile";
import MobileSideBar from "../../components/Mobile/MobileSideBar/MobileSideBar";
import { useSelector } from "react-redux";

function About({ token }) {
  let { width } = useWindowDimensions();
  const [showSideBar, setShowSideBar] = useState(false);
  const { isLoading } = useSelector((state) => state.users);
  useEffect(() => {
    console.log(isLoading);
  }, [isLoading]);

  const toggleShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  return (
    <>
      <div className="relative">
        {showSideBar && <MobileSideBar toggleShowSideBar={toggleShowSideBar} />}
        <header className="pt-3 pb-[3.75rem] mb-3 about-header h-auto relative">
          {width < 834 ? (
            <NavBarMobile toggleShowSideBar={toggleShowSideBar} />
          ) : (
            <NavBar />
          )}
          <Banner />
        </header>
        <main className="black-color">
          <WhyUs />
          <Achievements />
          {width > 375 ? (
            <Testimonials testimonials={testimonials} />
          ) : (
            <TestimonialsMobile testimonials={testimonials} />
          )}
          {/* <Team /> */}
          <Footer />
        </main>
      </div>
    </>
  );
}

export default About;
