import React from "react";
import "./TestimonialCard.css";

function TestimonialCard({
  testimonial: { person, profile, position, content },
}) {
  return (
    <div className="testimonial-card p-5 light-grey-bg text-black w-[300px] h-[267px] rounded-md flex flex-col justify-around items-center hover:-translate-y-2">
      <div className="w-full mb-3 header flex items-center justify-start">
        {/* <img src={profile} className="mr-5" alt="" /> */}
        <div className="flex flex-col">
          <b>{person}</b>
          <span>{position}</span>
        </div>
      </div>
      <div className="content">
        <p className="text-sm">{content}</p>
      </div>
    </div>
  );
}

export default TestimonialCard;
