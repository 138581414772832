import React from "react";
import "./Testimonials.css";
import TestimonialCard from "../../components/TestimonialCard/TestimonialCard";

function Testimonials({ testimonials }) {
  return (
    <div className="testimonials-container flex flex-wrap items-center justify-around py-12 px-24 my-3">
      <i className="ri-arrow-left-s-line text-4xl cursor-pointer"></i>
      {testimonials &&
        testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} testimonial={testimonial} />
        ))}
      <i className="ri-arrow-right-s-line text-4xl cursor-pointer"></i>
    </div>
  );
}

export default Testimonials;
