import React from "react";

function InputField({ label, placeholder, classNames, handleChange, value, type }) {
    return (
        <div className={`${classNames} my-3 text-sm`}>
            <label htmlFor="" className="text-xs font-medium">
                {label}
            </label>
            <div className="input-wrapper w-full mt-1 h-[38px] ">
                <input
                    type={type}
                    value={value ? value : ''}
                    onChange={(e) => handleChange(e)}
                    className={`w-full rounded-md h-full border-[1px] grey-border flex items-center font-medium text-xs justify-start pl-3 outline-none`}
                    required
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
}

export default InputField;
