import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "./analytics";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/About/About";
import Featured from "./pages/Featured/Featured";
import Review from "./pages/Review/Review";
import Login from "./pages/Login/Login";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "./redux/actions/user";
import Cookies from "universal-cookie";
import Submission from "./pages/Submission/Submission";
import Register from "./pages/Register/Register";
import { getSnippets } from "./redux/actions/snippets";
import Waitlist from "./pages/Waitlist/Waitlist";
import Contact from "./pages/Contact/Contact";
import Terms from "./pages/Terms/Terms";
import Privacypolicy from "./pages/Privacypolicy/Privacypolicy";
import Forgotpassword from "./pages/Forgotpassword/Forgotpassword";
import VerifyOTP from "./pages/Verifyotp/Verifyotp";
import Comingsoon from "./pages/Comingsoon/Comingsoon";
import User from "./pages/User/Users";
import NoPage from "./pages/NoPage/NoPage";
import Search from "./pages/Search/Search";

function App() {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname]);

  const dispatch = useDispatch();

  const cookies = new Cookies();
  const tokenCookie = cookies.get("token");

  useEffect(() => {
    if (tokenCookie) {
      dispatch(getCurrentUser(tokenCookie));
    }
  }, [tokenCookie, dispatch]);

  useEffect(() => {
    dispatch(getSnippets());
  }, [dispatch]);
  return (
    <Routes>
      <Route path="/about" element={<About />} token={tokenCookie} />
      <Route path="/" element={<About />} token={tokenCookie} />
      <Route path="/explore" element={<Featured token={tokenCookie} />} />
      <Route
        path="/explore/:language"
        element={<Featured token={tokenCookie} />}
      />
      <Route path="/search" element={<Search />} />
      <Route path="/review/:id" element={<Review />} />
      <Route path="/user" element={<User />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/submit" element={<Submission />} />
      <Route path="/waitlist" element={<Waitlist />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacypolicy" element={<Privacypolicy />} />
      <Route path="/forgotpassword" element={<Forgotpassword />} />
      <Route path="/verifyotp" element={<VerifyOTP />} />
      <Route path="/comingsoon" element={<Comingsoon />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
}

export default App;
