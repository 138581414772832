export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";

export const CREATE = "CREATE";
export const FETCH_ALL = "FETCH_ALL";
export const FILTER_SNIPPETS_BY_LANGUAGE = "FILTER_SNIPPETS_BY_LANGUAGE";

export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";
export const LOGOUT = "LOGOUT";

export const SEARCH_SNIPPETS = "SEARCH_SNIPPETS";