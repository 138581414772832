import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";

const notify = (message, type) => {
  if (type === "error") {
    toast.error(message, {
      style: {
        border: "1px solid #eccc",
        padding: "16px",
        color: "crimson",
      },
      iconTheme: {
        primary: "crimson",
        secondary: "#FFFAEE",
      },
    });
  } else if (type === "success") {
    toast.success(message);
  }
};
const Toast = ({ message, type }) => {
  useEffect(() => {
    notify(message, type);
  }, [message, type]);
  return (
    <div>
      <Toaster />
    </div>
  );
};
export default Toast;
