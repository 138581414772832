import React from "react";

function LanguageTag({ lang, numSnippets, active }) {
  return (
    <div
      className={`${
        active ? "primary-bg text-white" : "light-grey-bg"
      } flex text-xs rounded-md px-2 py-[0.35em] mx-2 cursor-pointer font-medium`}
    >
      <span className="px-1">{lang?.charAt(0).toUpperCase() + lang.slice(1)}</span>
      <span className="px-1">{numSnippets ? numSnippets : 0}</span>
    </div>
  );
}

export default LanguageTag;
