import React from 'react'
import { useSearchParams } from 'react-router-dom';
import "./SearchField.css";

function SearchField({bgColor, handleSearchChange}) {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q'); // Get the 'q' parameter from URL

  return (
    <div className={`search-field py-[0.2rem] px-2 mx-1 w-[45%] ${bgColor} rounded-md flex items-center justify-start max-w-[300px]`}>
        <i className="ri-search-line grey-color mr-2 border-r-2 pr-1"></i>
        <input type="text" placeholder='Search here' className={`${bgColor} text-sm grey-color border-none outline-none w-[90%]`} onKeyDown={handleSearchChange} onChange={handleSearchChange} defaultValue={query} />
    </div>
  )
}

export default SearchField