import React, { useState } from "react";
import { axiosInstance } from "../../utils/config";

const VerifyOTP = () => {
  const [otp, setOTP] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    axiosInstance
      .post("/verify_otp/", { otp: otp })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data);
          console.log("I will now redirect you to the password reset page");
          // redirect user to password reset page
        } else {
          // show error message to user
        }
      })
      .catch((error) => {
        // show error message to user
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter OTP"
          onChange={(e) => {
            setOTP(e.target.value);
          }}
          required
        />
        <button type="submit">Verify OTP</button>
      </form>
    </>
  );
};

export default VerifyOTP;
