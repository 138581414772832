import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import ReactMDE from 'react-mde';
import * as Showdown from 'showdown';
import "./CommentWidget.css"
import 'react-mde/lib/styles/css/react-mde-all.css';
import Moment from "react-moment";
import { axiosInstance } from '../../utils/config';
import { Icon } from '@iconify/react';


const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
});

const CommentWidget = ({ comment }) => {
    return (
        <div className="relative commentContainer min-h-[105px] bg-[#E0F1BD] w-10/12 mx-5 text-black my-2 p-1 rounded-sm flex flex-col ">
            <div className="px-[10px] flex items-start justify-between font-bold">
                <div className="author">{comment.author ? comment.author.charAt(0).toUpperCase() + comment.author.slice(1) : ""}</div>
                <div className="date text-xs">
                    {comment.published_date ? (

                        <Moment format="D MMM YYYY" withTitle>
                            {comment.published_date}
                        </Moment>
                    ) : ("")}
                </div>
            </div>
            <div>
                <ReactMDE
                    className={'text-sm comment__textarea w-9/12'}
                    value={comment.text.charAt(0).toUpperCase() + comment.text.slice(1)}
                    selectedTab={'preview'}
                    readOnly
                    classes={{
                        toolbar: 'comment__toolbar hidden',
                    }}
                    minPreviewHeight={10}
                    generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))
                    }
                />
            </div>
            <div className="absolute -right-10 flex flex-col flex-wrap gap-1 h-[105px]">
                <Icon icon="akar-icons:facebook-fill" className="text-[#3B5998] text-[23px] cursor-pointer" />
                <Icon icon="ant-design:twitter-circle-filled" className="text-[#0EB7ED] text-[23px] cursor-pointer"/>
                <Icon icon="entypo-social:linkedin-with-circle" className="text-[#007EB0] text-[23px] cursor-pointer"/>
                <Icon icon="logos:reddit-icon" className="text-[23px] cursor-pointer"/>
                <Icon icon="akar-icons:whatsapp-fill" className="text-[23px] bg-[#25D267] text-white rounded-full cursor-pointer"/>
                <Icon icon="fluent:copy-16-regular" className="text-[23px] bg-[#A9A9A9] rounded-full text-white p-1 cursor-pointer"/>
            </div>
        </div>
    );
};

export const addCommentLineWidget = (cm, comment) => {
    const commentContainer = document.createElement('div');
    commentContainer.className = "commentContainer";

    // // "commentContainer";
    const root = ReactDOM.createRoot(commentContainer);
    root.render(<CommentWidget comment={comment} />);
    cm.addLineWidget(comment.line, commentContainer);
};

export default CommentWidget;
