import ReactGA from "react-ga";

// Replace `GA_TRACKING_ID` with your actual tracking ID
ReactGA.initialize("G-HDGP9GNL94");

// Function to track page views
export const trackPageView = (pathname) => {
  ReactGA.set({ page: pathname });
  ReactGA.pageview(pathname);
};

// Function to track any click event
export const trackClickEvent = (event) => {
  ReactGA.event({
    category: "Click",
    action: "Click",
    label: event.target.nodeName.toLowerCase(),
    value: 1,
  });
};
