import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import InputField from "../../components/InputField/InputField";
import LightNavBar from "../../components/LightNavBar/LightNavBar";
import TextArea from "../../components/TextArea/TextArea";
import BlueButton from "../../components/BlueButton/BlueButton";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/elegant.css";
import "codemirror/theme/material.css";
import { axiosInstance } from "../../utils/config";
import Toast from "../../components/Toast/Toast";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import RelatedSnippets from "../../sections/RelatedSnippets/RelatedSnippets";

function Waitlist() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [source, setSource] = useState("");

  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSubmission = () => {
    setSubmitting(true);
    if (!email) {
      setError("Email must not be empty!");
      setSubmitting(false);
      return;
    }
    if (!username) {
      setError("Title must not be empty!");
      setSubmitting(false);
    }
    axiosInstance
      .post("/snippets/", {
        email,
      })
      .then((data) => {
        setSubmitting(false);
        setShowModal(true);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  };
  return (
    <div className="relative font-poppins">
      <LightNavBar active="waitlist" />
      {error && <Toast message={error} type="error" />}
      {showModal && <ConfirmationModal id={77} />}
      <div className="page-container px-10 content flex itemsc-center justify-around">
        <div className="right w-7/12  my-10">
          <form action="">
            <div className="waitlist-info black-bg text-white w-full h-[139px] rounded-md flex items-center justify-center px-10 text-sm font-medium text-center py-6">
              We are developing a code review site for candidates interviewing
              in software companies like Google, Apple, Netflix etc. For
              example, visit out home page at
              https://www.interviewblindspots.comwaitlist Enter your contact
              information here if you want us to keep you updated.
            </div>
            <InputField
              classNames="my-7"
              label="Email *"
              value={email}
              handleChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
            />
            <InputField
              classNames="my-7"
              label="Username *"
              value={username}
              handleChange={(e) => setUsername(e.target.value)}
              placeholder="Choose a username(We will try and reserve it when the site goes live) "
            />
            <TextArea
              classNames="my-16"
              innerClassNames="h-[89px]"
              label="About *"
              placeholder="Add information about yourself * "
            />
            <InputField
              value={source}
              handleChange={(e) => setSource(e.target.value)}
              classNames="my-7"
              label="Which website did you join us from? *"
              placeholder="Url/website name"
            />
            <TextArea
              classNames="my-16"
              innerClassNames="h-[89px]"
              label="Painpoints"
              placeholder="What more features would you like to see in current interview
                            preparation sites? "
            />
          </form>
          <p className="my-6 text-sm">
            In order for us to give you feedback on your code, please ensure
            that you submit the complete question along with your solution.
            Submissions with inadequate context will be ignored.
          </p>
          <div onClick={handleSubmission}>
            <BlueButton>
              {submitting ? "Submitting..." : "Submit your code"}
            </BlueButton>
          </div>
        </div>
        <div className="left w-3/12">
          <RelatedSnippets />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Waitlist;
